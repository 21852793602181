<template>
  <v-dialog width="450" v-model="dialog">
    <template v-slot:activator="{ on }">
      <base-border-card :title="'Artista'" @card_clicked="dialog = true">
        <template slot="corner_action">
          <v-tooltip
            v-if="!$vuetify.breakpoint.mdAndDown"
            bottom
            :max-width="450"
          >
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="$vuetify.breakpoint.smAndDown ? '' : on"
                color="red"
                class="ma-2"
                >mdi-information-outline</v-icon
              >
            </template>

            <span> {{ $t("artist_description") }}</span>
          </v-tooltip>
          <v-menu
            :max-width="300"
            v-if="$vuetify.breakpoint.mdAndDown"
            :close-on-content-click="true"
          >
            <template v-slot:activator="{ on }">
              <v-icon class="ma-2" v-on="on" color="red"
                >mdi-information-outline</v-icon
              >
            </template>
            <p>
              <span> {{ $t("artist_description") }}</span>
            </p></v-menu
          >
        </template>
      </base-border-card>
    </template>
    <v-card>
      <div class="close">
        <v-btn icon @click="dialog = false" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card-title> Artista </v-card-title>
      <v-card-text class="primary--text text-justify"
        >{{ $t("artist_description") }}
        <v-row>
          <v-col>
            <v-simple-table v-if="loaded" class="mx-1">
              <tr>
                <th width="250" class="font-weight-regular">Notificación</th>
                <th width="50" class="font-weight-regular text-center">
                  Internal
                </th>
                <th width="50" class="font-weight-regular text-center">
                  Email
                </th>
              </tr>
              <tr v-for="(n, i) in notifications" :key="i">
                <td class="py-0">{{ $t("notifications.internal." + n) }}</td>
                <td class="py-0">
                  <v-select
                    :items="yesNo"
                    outlined
                    dense
                    hide-details
                    v-model="notifications_values[n][1]"
                  ></v-select>
                </td>
                <td class="py-0">
                  <v-select
                    :items="yesNo"
                    outlined
                    dense
                    hide-details
                    v-model="notifications_values[n][2]"
                  ></v-select>
                </td>
              </tr>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn outlined height="25" @click="dialog = false">
          {{ $t("cancel", { name: "" }) }}
        </v-btn>
        <v-btn
          color="primary"
          @click="save"
          elevation="0"
          style="
            width: 100px !important;
            color: #363533;
            height: 25px;
            padding-top: 10px !important;
          "
        >
          <v-icon small style="margin-right: 5px" size="14px">$save</v-icon>
          {{ $t("save", { name: $t("") }) }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "ArtistNotifications",
  data() {
    return {
      dialog: false,
      loaded: false,
      yesNo: [
        { text: this.$t("yes"), value: true },
        { text: this.$t("no"), value: false },
      ],
      notifications: [
        "NewAppointmentNotification",
        "NewDesignAddedNotification",
        "ShareArchiveNotification",
        "PayedNotification",
      ],
      notifications_values: {},
    };
  },
  mounted() {
    this.getNotifications();
  },
  methods: {
    ...mapActions("users", ["settingUpdate", "getSetting"]),
    getNotifications() {
      this.getSetting({ key: "external_notifications_via_tattooer" }).then(
        (response) => {
          if (response.data) response = response.data.value;
          console.log("response", response);

          this.notifications.forEach((n) => {
            if (response[n]) this.notifications_values[n] = response[n];
            else this.notifications_values[n] = { 1: false, 2: false };
          });
          this.loaded = true;
        }
      );
    },
    ...mapActions("notifications", ["saveNotificationConfiguration"]),
    save() {
      console.log("saving");
      this.saveNotificationConfiguration({
        type: "tattooer",
        value: this.notifications_values,
      }).then((response) => {
        this.dialog = false;
        this.$alert(this.$t("save_ok"));
      });
    },
  },
};
</script>

<style lang="sass" scoped>
th
  text-transform: uppercase
  text-decoration: underline
  padding-bottom: 4px
td
  color: var(--v-primary-base)
</style>
